import { Typography } from "@material-ui/core";
import React from "react";
import styles from "./Policy.module.scss";

export default () => {
  return (
    <div className={styles.root}>
      <Typography variant="h1">活動ポリシー</Typography>

      <Typography className={styles.lead}>
        箕澤屋の活動を無理なく続けていくためのポリシーです。
      </Typography>

      <div className={styles.policy}>
        <div className={styles.items}>
          <div className={styles.item}>
            <Typography variant="h2" className={styles.item__header}>
              活動の目的
            </Typography>
            <Typography>
              箕澤屋の建物を残すと共に、参加メンバーの活動拠点として居心地のよい場をつくること
              <br />
              <br />
              つまり、
              <strong>「箕澤屋を拠点にしたゆるーいつながりの場づくり」</strong>
              です。
            </Typography>
          </div>
          <div className={styles.item}>
            <Typography variant="h2" className={styles.item__header}>
              メンバーについて
            </Typography>
            <div className={styles.item__content}>
              <Typography variant="h3" className={styles.item__header_s}>
                箕澤屋メンバーとは
              </Typography>
              箕澤屋の活動目的に賛同し、下記の基準を満たす方を箕澤屋メンバーとさせていただきます。
              <br />
              ・少なくとも1度は箕澤屋に訪れたことのある方
              <br />
              ・箕澤屋の活動を年間で1日以上手伝っていただいた方（仮）
              <br />
            </div>
            <div className={styles.item__content}>
              <Typography variant="h3" className={styles.item__header_s}>
                参加のルール
              </Typography>
              ・箕澤屋を大切に扱う
              <br />
              ・メンバー1人ひとりを尊重し、良好な関係を保つ <br />
              ・自分が楽しむ
            </div>
            <div className={styles.item__content}>
              <Typography variant="h3" className={styles.item__header_s}>
                メンバーのメリット
              </Typography>
              ・メンバー同士の交流
              <br />
              ・箕澤屋の施設を遊び場として使える
              <br />
              ・自分がやりたいことを実現する場として利用できる
              <br />
              {/* <small>
                （無制限にOKというわけにはいかないので、まずはアイデアとして意見いただければ管理メンバーで検討します）
              </small> */}
            </div>

            <div className={styles.item__content}>
              <Typography variant="h3" className={styles.item__header_s}>
                「QULA」とは？
              </Typography>
              コロナ禍でイベントができなくなったので、オンラインでできることをと始めた実験の場です。
              <br />
              <br />
              ちなみに、QULAという名前の由来は、古民家だから蔵というのもあるけれど、開発メンバーで最初につけた名前が
              <b>「MISAWAYA CLOUD」</b>
              。<br />
              その後、CLOUDはバズワードっぽいから避けたいねということで
              <b>「MISAWAYA NO KURA」</b>に変更。
              <br />
              しかし、長い名前は呼びにくいのと、KURAとつけている古民家カフェや場所がたくさんあるので、文字って
              <b>「QULA」</b>としました。
              <br />
              ネーミングはまだβ版です。将来また変わるかも。
              <br />
              <br />
              ※現在は、テスト運用としてコアメンバーのみです。（おそらく今年中は近い知り合いのみの予定です）
              <br />
              営利目的ではないけれど、将来的には運営費を稼げる仕組みが作れたらいいなーというのが、秘めた想いです。
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
