import React from "react"
import Layout from "../../components/member/Layout"
import Policy from "../../components/member/Policy/Policy"

export default props => {
  const pageSlug = "policy"
  const pageTitle = "活動ポリシー"
  return (
    <Layout pageSlug={pageSlug} pageTitle={pageTitle}>
      <Policy />
    </Layout>
  )
}
